<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <h1>Confirm Details</h1>
        <p></p>
        <form
          name="form"
          id="x1"
          method="POST"
          :action="payNowNetCash"
          target="_top"
        >
          <!---Form POST **--->
          <input
            type="text"
            name="M1"
            :value="payNowServiceKey"
            class="hide-input"
          />
          <!--- YOUR PAY NOW SERVICE KEY GOES IN HERE --->
          <input
            type="text"
            name="m2"
            :value="payNowSoftwareVendorKey"
            class="hide-input"
          />
          <!--- SOFTWARE VENDOR KEY GOES IN HERE --->
          <v-row>
            <v-col cols="6">
              <label>Payment Reference:</label>
            </v-col>
            <v-col cols="6">
              <!--input type="text" name="p2" value="6346a5422faa502ffa6b56ef"> ---Unique ID for this / each transaction--->
              <input
                class="input-box"
                type="text"
                name="p2"
                :value="generatePaymentReference()"
              />

              <!---Unique ID for this / each transaction--->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label>Payment for employee:</label>
            </v-col>
            <v-col cols="6">
              <input
                class="input-box"
                type="text"
                name="p3"
                v-model="employeeFullName"
              />
              <!---Description of goods being purchased--->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label>Amount:</label>
            </v-col>
            <v-col cols="6">
              <div class="input-box">
                <span class="prefix">R</span>
                <input type="text" name="p4" :value="getRealAmount()" />
              </div>
              <p>(for this month)</p>
              <!---Amount to be settled / paid--->
            </v-col>
          </v-row>
          <input type="text" name="Budget" value="N" class="hide-input" />
          <input
            type="text"
            name="p3"
            :value="generatePaymentReference()"
            class="hide-input"
          />
          <!---Compulsory should be Y--->
          <input type="text" name="m4" value="Extra 1" class="hide-input" />
          <!---This is an extra field ***--->
          <input type="text" name="m5" value="Extra 2" class="hide-input" />
          <!---This is an extra field ***--->
          <input type="text" name="m6" value="Extra 3" class="hide-input" />
          <!---This is an extra field ***--->
          <v-row>
            <v-col cols="6">
              <label>Email Address:</label>
            </v-col>
            <v-col cols="6">
              <input
                class="input-box"
                type="text"
                name="m9"
                v-model="me.email"
              />
              <!---Card holders email address--->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label>Cellphone Number:</label>
            </v-col>
            <v-col cols="6">
              <input
                class="input-box"
                type="text"
                name="m11"
                :value="cleanedTelNumber()"
              />
              <!---Card holders mobile number--->
            </v-col>
          </v-row>
          <input
            type="text"
            name="m10"
            value="Demo attempt for testing"
            class="hide-input"
          />
          <!---Dataset used by shopping carts i.e. OSCommerce--->
          <input type="text" name="m14" value="1" class="hide-input" />
          <!---Request credit card subscription token 0 = no | 1 = yes--->
          <input type="text" name="m15" value="1" class="hide-input" />
          <!---Credit card token for subscription cards--->
          <input type="text" name="m16" value="1" class="hide-input" />
          <!---Subscription indicator 0 = no | 1 = yes--->
          <input
            type="text"
            name="m17"
            :value="this.payment.duration"
            class="hide-input"
          />
          <!---Number of cycles for subscription payment--->
          <input type="text" name="m18" value="1" class="hide-input" />
          <!---Subscription frequency--->
          <v-row>
            <v-col cols="6">
              <label>Subscription start Date:</label>
            </v-col>
            <v-col cols="6">
              <input type="text" name="m19" :value="cleanedDate()" disabled />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label>Subscription Fee:</label>
            </v-col>
            <v-col cols="6">
              <!---Subscription recurring amount--->
              <div class="input-box">
                <span class="prefix">R</span>
                <input type="text" name="m20" :value="totalPayment" disabled />
              </div>
            </v-col>
          </v-row>
          <p></p>
          <v-btn color="#7D72F5" class="app-btn2 tertiary white--text" block>
            <v-icon>mdi-cash-multiple</v-icon>

            <input name="submit" type="submit" value="PAY" />
            <!---Submit button--->
          </v-btn>
        </form>
      </v-col>
    </v-row>
    <p></p>
    <v-row>
      <v-col cols="12">
        <p style="margin-left: 10%; margin-right: 10%">
          Please Note that You will be redirected to a payment gateway to
          process your payment
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { User } from "@/models/User";
import { Payment } from "@/models/Payment";
import { gql } from "apollo-boost";

export default {
  components: {},
  name: "Process Payment",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    employeeFullName: {
      type: String,
      default: "",
    },
    totalPayment: {
      type: Number,
      default: "",
    },
    paymentCurrencyCode: {
      type: String,
      default: "ZAR",
    },
    userId: {
      type: String,
      default: "",
    },
    createdById: {
      type: String,
      default: "",
    }
  },
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      selectedItem: 1,
      subscriptionDate: "2021-01-01",
      paymentReference: "",
      isLoading: false,
      paymentModel: new Payment(),
      userModel: new User(),
      process: [""],
      payNowNetCash: process.env.VUE_APP_PAYNOW_NETCASH,
      payNowServiceKey: process.env.VUE_APP_PAYNOW_SERVICE_KEY,
      payNowSoftwareVendorKey: process.env.VUE_APP_PAYNOW_SOFTWARE_VENDOR_KEY,
      otherKey: process.env.VUE_APP_OTHER_PAY_NOW_KEY,
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          telNumber
        }
      }
    `,
    payment: {}
  },
  methods: {
    generatePaymentReference() {
      const reference = Math.random().toString(36).slice(-8).toUpperCase();
      const fairReference = "FAIR-" + reference;
      this.paymentReference = fairReference;
      return fairReference;
    },
    cleanedDate() {
      const date = this.$moment().format("YYYY-MM-DD")
      return date
    },
    cleanedTelNumber() {
      return this.me.telNumber.replace(/[^0-9]/g, "").substring(0, 10);
    },
    getRealAmount() {
      return this.totalPayment
    },
  },
  computed: {
  },
};
</script>

<style scoped>
/*process payment*/
.hide-input {
  display: none;
}

.input-box {
  display: flex;
  align-items: center;
  max-width: 300px;
  background: #fff;
  border: none;

  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.input-box .prefix {
  font-weight: 300;
  font-size: 14px;
  color: #999;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box:focus-within {
  border: none;
}
</style>
